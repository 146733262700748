<template>


  <div style="width: 100%">
    <div v-if="element.config.url"
         class="d-flex align-center"
    >
      <v-icon class="mr-3" :color="wsACCENT">mdi-paperclip</v-icon>
      <h5 :style="`color : ${wsACCENT}`">{{ element.content }}</h5>

    </div>
    <ws-file-uploader
        v-else-if="!preview"
        @success="addFile"
        public
    />
  </div>

</template>

<script>
import finemailer from "@/modules/finemailer/mixins/finemailer";

export default {
  name: "emailTextElementEditor",
  mixins : [finemailer],
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  computed : {
    lineStyle() {
      let style = ''
      style += `border-color : ${this.EMAIL_COLOR(this.element.config.color)};`
      style += `border-width : ${this.EMAIL_COLOR(this.element.config.size || 1)}px`
      return style
    }
  },
  methods : {
    addFile(event) {
      let element  = this.element
      element.content = event.name
      element.config.url = event.url
      this.$store.state.finemailer.selectedElement = this.COPY(element)
      this.$store.state.finemailer.elementEditTrigger++

    }
  },
  mounted() {}
}
</script>

<style scoped>
</style>