<template>
  <img ref="img" v-if="element.content" height="100%" :width="`${element.config.width || 100}%`" :src="element.content" />
  <v-sheet width="100%" v-else-if="!preview" :color="wsATTENTION">
    <image-param-picker
        @input="editImage"
        :value="element.content"
        disable-portal
    />
  </v-sheet>

</template>

<script>
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
export default {
  name: "emailImageElementEditor",
  components : {
    imageParamPicker
  },
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  methods : {
    editImage(event) {
      let element = this.element
      element.content = event
      this.$store.state.finemailer.selectedElement = this.COPY(element)
      this.$store.state.finemailer.elementEditTrigger++
    }
  }
}
</script>

<style scoped>

</style>